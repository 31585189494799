import * as tslib_1 from "tslib";
import { BehaviorSubject, Subject } from "rxjs";
import { LocalForageService } from "./local-forage.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../environments/environment";
import { Router } from "@angular/router";
import { getStoreData } from "../app/utils";
export class DataService {
    constructor(localForageService, http, router) {
        this.localForageService = localForageService;
        this.http = http;
        this.router = router;
        this.cartSize = new BehaviorSubject(0);
        this.cartWeight = new BehaviorSubject({ weight: 0.0, dist: {} });
        this.loading = new BehaviorSubject(false);
        this.simpleToastMessage = new Subject();
        this.pinnedLocation = new Subject();
        this.pendingOrderCard = new Subject();
    }
    getCartSize() {
        return this.cartSize.asObservable();
    }
    getCartWeight() {
        return this.cartWeight.asObservable();
    }
    getLoading() {
        return this.loading.asObservable();
    }
    getSimpleToastMessage() {
        return this.simpleToastMessage.asObservable();
    }
    ping() {
        return this.http.get(`${environment.apiServer}/ping/`);
    }
    updateCartSize() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let weight = 0;
            const cart = ((yield this.localForageService.getItem('hilyfe', 'cart')) || { data: [] }).data;
            this.cartSize.next(cart.length);
            const dists = {};
            yield cart.forEach(item => {
                weight += parseFloat(item.variant.product_size.max) * item.quantity;
                const cartDist = dists[item.variant.slug];
                if (cartDist) {
                    cartDist.push(item.quantity);
                }
                else {
                    dists[item.variant.slug] = [item.quantity];
                }
            });
            this.cartWeight.next({ weight, dists });
        });
    }
    getCollections() {
        return this.http.get(`${environment.apiServer}/collections/`);
    }
    getOrders() {
        return this.http.get(`${environment.apiServer}/orders/new-and-assigned/`);
    }
    getPendingOrders() {
        return this.http.get(`${environment.apiServer}/orders/pending/`);
    }
    getSuggestions(s) {
        return this.http.get(`${environment.apiServer}/location/autocomplete/?s=${s}`);
    }
    changeLoadingState(state) {
        this.loading.next(state);
    }
    showSimpleToastMessage(message, duration = null, color = 'dark', position = 'bottom') {
        this.simpleToastMessage.next({ message, duration, color, position });
    }
    getPinnedLocation() {
        return this.pinnedLocation.asObservable();
    }
    getPlaceDetails(placeID) {
        return this.http.get(`${environment.apiServer}/location/place-details/?place_id=${placeID}`);
    }
    reverseGeocoding(lat, lng) {
        return this.http.get(`${environment.apiServer}/location/reverse-geocoding/?lat=${lat}&lng=${lng}`);
    }
    updatePinnedLocation(pinnedLocation) {
        this.pinnedLocation.next(pinnedLocation);
    }
    saveLocationDetails(formattedAddress, lat, lng, comment, locationFor) {
        return this.http.post(`${environment.apiServer}/location/save-details/`, {
            formatted_address: formattedAddress,
            lat,
            lng,
            comment,
            location_for: locationFor
        });
    }
    getDeliveryLocations() {
        return this.http.get(`${environment.apiServer}/location/`);
    }
    orderFulfilled(reference, method) {
        return this.http.post(`${environment.apiServer}/orders/fulfilled/`, {
            reference,
            method,
        });
    }
    getSessionUrl(order) {
        return this.http.post(`${environment.apiServer}/stripe_create_session/`, order);
    }
    getOrder(reference) {
        return this.http.get(`${environment.apiServer}/orders/${reference}/`);
    }
    getProduct(slug) {
        return this.http.get(`${environment.apiServer}/product/${slug}/`);
    }
    acceptOrder(reference, eta) {
        return this.http.post(`${environment.apiServer}/orders/accept/${reference}/`, {
            eta
        });
    }
    getDistanceMatrix(origin, destination) {
        return this.http.post(`${environment.apiServer}/location/matrix/`, {
            origin, destination
        });
    }
    getProductGramMeasure(deliveryman, slug = null) {
        if (slug) {
            return this.http.get(`${environment.apiServer}/product-measure/${deliveryman}/${slug}/`);
        }
        else {
            return this.http.get(`${environment.apiServer}/product-measure/${deliveryman}/`);
        }
    }
    getEarnings(earningType) {
        return this.http.get(`${environment.apiServer}/earnings/${earningType}/`);
    }
    saveOfflineOrder(username, cart) {
        return this.http.post(`${environment.apiServer}/orders/save-offline-order/`, {
            username,
            cart: cart.map(item => {
                return {
                    variant: item.variant.slug,
                    quantity: item.quantity,
                };
            }),
        });
    }
    generateReferralCode(username) {
        return this.http.post(`${environment.apiServer}/accounts/generate-referral-code/`, {
            username,
        });
    }
    getUsernameSuggestions(username) {
        return this.http.get(`${environment.apiServer}/suggest-username/?s=${username}`);
    }
    placeCashOnDeliveryOrder(order) {
        return this.http.post(`${environment.apiServer}/orders/cash-on-delivery-order/`, order);
    }
    validateDiscountCode(discount) {
        return this.http.post(`${environment.apiServer}/orders/validate-discount-code/`, {
            discount,
        });
    }
    getPayouts() {
        return this.http.get(`${environment.apiServer}/payouts/`);
    }
    payout(username) {
        return this.http.post(`${environment.apiServer}/payout/${username}/`, {});
    }
    getReferrals() {
        return this.http.get(`${environment.apiServer}/referrals/`);
    }
    getDiscounts() {
        return this.http.get(`${environment.apiServer}/discounts/`);
    }
    approveAccount(username, code) {
        return this.http.post(`${environment.apiServer}/accounts/approve/`, {
            username,
            code
        });
    }
    showPendingOrderCard(state) {
        this.pendingOrderCard.next(state);
    }
    getPendingOrderCard() {
        return this.pendingOrderCard.asObservable();
    }
    handleNoCredentialsError(error) {
        if (error.status === 401) {
            localStorage.clear();
            this.router.navigate(['welcome'], { queryParams: { next: window.location.pathname + window.location.search } });
        }
        else {
            return error;
        }
    }
    cancelOrder(reference, by, reason) {
        return this.http.post(`${environment.apiServer}/orders/cancel/${reference}/`, { by, reason });
    }
    getDeliverymen() {
        return this.http.get(`${environment.apiServer}/deliverymen/`);
    }
    getTerms() {
        return this.http.get(`${environment.apiServer}/terms/`);
    }
    transferOrder(reference, username) {
        return this.http.post(`${environment.apiServer}/orders/transfer/${reference}/`, {
            username
        });
    }
    getReplenishDates(date = null) {
        if (date) {
            return this.http.get(`${environment.apiServer}/replenish/${date}/`);
        }
        else {
            return this.http.get(`${environment.apiServer}/replenish/`);
        }
    }
    getOptimizedRoutes() {
        return this.http.get(`${environment.apiServer}/optimized-routes/`);
    }
    requestReApproval() {
        return this.http.post(`${environment.apiServer}/request-re-approval/`, {});
    }
    getProductBatches() {
        return this.http.get(`${environment.apiServer}/product-batches/`);
    }
    assignInventories(inventoryAssignments) {
        return this.http.post(`${environment.apiServer}/inventory-assignments/new/`, { inventory_assignments: inventoryAssignments });
    }
    getInventoryAssignments(deliveryman = null) {
        const params = new HttpParams({ fromObject: { deliveryman } });
        return this.http.get(`${environment.apiServer}/inventory-assignments/`, { params });
    }
    getInventoryAssignment(inventoryId) {
        return this.http.get(`${environment.apiServer}/inventory-assignments/${inventoryId}/`, {});
    }
    cart() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const items = yield getStoreData(this.localForageService, 'cart', []);
            if (items.length) {
                const productVariants = yield this.getVariants(items.map(item => item.variant.slug)).toPromise();
                let cart = Object.create(items);
                cart = cart.map(item => {
                    return {
                        variant: productVariants.find(subItem => subItem.slug === item.variant.slug),
                        quantity: item.quantity
                    };
                });
                return cart;
            }
            return items;
        });
    }
    getVariants(slugs) {
        return this.http.post(`${environment.apiServer}/variants/`, { slugs });
    }
}
