/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./order-accept.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/ionic-angular.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "../order-summary/order-summary.component.ngfactory";
import * as i6 from "../order-summary/order-summary.component";
import * as i7 from "../../../../_services/authentication.service";
import * as i8 from "../../../../_services/data.service";
import * as i9 from "../../../../_services/location.service";
import * as i10 from "@angular/material/bottom-sheet";
import * as i11 from "@angular/platform-browser";
import * as i12 from "./order-accept.component";
import * as i13 from "@angular/router";
import * as i14 from "../../../../_services/local-forage.service";
var styles_OrderAcceptComponent = [i0.styles];
var RenderType_OrderAcceptComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrderAcceptComponent, data: {} });
export { RenderType_OrderAcceptComponent as RenderType_OrderAcceptComponent };
function View_OrderAcceptComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "w3-center w3-padding-64"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This order was cancelled."]))], null, null); }
function View_OrderAcceptComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "w3-center w3-padding-64"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This order is currently assigned to another deliveryman."]))], null, null); }
function View_OrderAcceptComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-button", [["color", "primary"], ["expand", "block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.provideETA() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], expand: [1, "expand"] }, null), (_l()(), i1.ɵted(-1, 0, [" Accept order "]))], function (_ck, _v) { var currVal_0 = "primary"; var currVal_1 = "block"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_OrderAcceptComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "w3-red w3-padding-large w3-center w3-round-xlarge"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" You can only accept new order when you have less than ", " pending orders. "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.maxAccept; _ck(_v, 1, 0, currVal_0); }); }
function View_OrderAcceptComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["accept", ""]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderAcceptComponent_5)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderAcceptComponent_6)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pendingOrdersCount < _co.maxAccept); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.pendingOrdersCount >= _co.maxAccept); _ck(_v, 4, 0, currVal_1); }, null); }
function View_OrderAcceptComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-order-summary", [["view", "delivery"]], null, null, null, i5.View_OrderSummaryComponent_0, i5.RenderType_OrderSummaryComponent)), i1.ɵdid(1, 114688, null, 0, i6.OrderSummaryComponent, [i7.AuthenticationService, i8.DataService, i3.AlertController, i3.ActionSheetController, i3.ModalController, i9.LocationService, i10.MAT_BOTTOM_SHEET_DATA, i11.DomSanitizer], { order: [0, "order"], view: [1, "view"], showSummary: [2, "showSummary"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_OrderAcceptComponent_4)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.order; var currVal_1 = "delivery"; var currVal_2 = !!((_co.order == null) ? null : _co.order.deliveryman_assigned); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = ((((_co.order == null) ? null : _co.order.deliveryman_assigned) === null) && (_co.order.state !== "cancelled")); _ck(_v, 3, 0, currVal_3); }, null); }
export function View_OrderAcceptComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ion-header", [["class", "ion-no-border"]], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { translucent: [0, "translucent"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 9, "ion-toolbar", [], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, [" New Order "])), (_l()(), i1.ɵeld(7, 0, null, 0, 4, "ion-buttons", [["slot", "start"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(8, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "ion-back-button", [["defaultHref", "/tabs/delivery"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonBackButton_0, i2.RenderType_IonBackButton)), i1.ɵdid(10, 49152, null, 0, i3.IonBackButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { defaultHref: [0, "defaultHref"] }, null), i1.ɵdid(11, 16384, null, 0, i3.IonBackButtonDelegate, [[2, i3.IonRouterOutlet], i3.NavController], { defaultHref: [0, "defaultHref"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 8, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(13, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { fullscreen: [0, "fullscreen"] }, null), (_l()(), i1.ɵeld(14, 0, null, 0, 6, "div", [["class", "w3-container w3-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderAcceptComponent_1)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderAcceptComponent_2)), i1.ɵdid(18, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderAcceptComponent_3)), i1.ɵdid(20, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = "/tabs/delivery"; _ck(_v, 10, 0, currVal_1); var currVal_2 = "/tabs/delivery"; _ck(_v, 11, 0, currVal_2); var currVal_3 = true; _ck(_v, 13, 0, currVal_3); var currVal_4 = (_co.order && (_co.order.state === "cancelled")); _ck(_v, 16, 0, currVal_4); var currVal_5 = ((_co.order && _co.order.deliveryman_assigned) && (_co.order.deliveryman_assigned.username !== _co.authUser.user.username)); _ck(_v, 18, 0, currVal_5); var currVal_6 = _co.order; _ck(_v, 20, 0, currVal_6); }, null); }
export function View_OrderAcceptComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-order-accept", [], null, null, null, View_OrderAcceptComponent_0, RenderType_OrderAcceptComponent)), i1.ɵdid(1, 49152, null, 0, i12.OrderAcceptComponent, [i8.DataService, i13.ActivatedRoute, i13.Router, i7.AuthenticationService, i3.ActionSheetController, i3.AlertController, i9.LocationService, i14.LocalForageService], null, null)], null, null); }
var OrderAcceptComponentNgFactory = i1.ɵccf("app-order-accept", i12.OrderAcceptComponent, View_OrderAcceptComponent_Host_0, {}, {}, []);
export { OrderAcceptComponentNgFactory as OrderAcceptComponentNgFactory };
