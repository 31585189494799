import {Component, OnInit} from '@angular/core';
import {LocalForageService} from "../_services/local-forage.service";
import {DataService} from "../_services/data.service";
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from "@angular/router";
import {AuthenticationService} from "../_services/authentication.service";
import {ToastController} from "@ionic/angular";
import {environment} from "../environments/environment";


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    loading = false;
    private toast: HTMLIonToastElement;

    constructor(
        private localForageService: LocalForageService,
        public toastController: ToastController,
        private dataService: DataService,
        private router: Router) {
        router.events.subscribe((routerEvent: any) => {
            this.checkRouterEvent(routerEvent);
        });
    }

    get authedUser() {
        return AuthenticationService.authedUser();
    }

    ngOnInit(): void {
        localStorage.setItem('version', environment.VERSION);
        this.dataService.getLoading().subscribe(loading => {
            this.loading = loading;
        });
        this.dataService.getSimpleToastMessage().subscribe(response => {
            this.presentToast(response.message, response.duration, response.color, response.position);
        });
    }

    async presentToast(message, duration = 5000, color = 'dark', position: 'top' | 'bottom' | 'middle' = 'bottom') {
        if (this.toast) {
            this.toast.dismiss();
        }
        this.toast = await this.toastController.create({
            message,
            duration,
            position,
            color,
            mode: 'ios',
            cssClass: 'tabs-bottom w3-animate-bottom',
            buttons: [
                {
                    side: 'start',
                    icon: 'notifications',
                },
                {
                    text: 'Dismiss',
                    role: 'cancel',
                }
            ]
        });
        this.toast.present();
    }

    private checkRouterEvent(routerEvent: Event): void {
        if (routerEvent instanceof NavigationStart) {
            this.dataService.changeLoadingState(true);
        }
        if (
            routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationCancel ||
            routerEvent instanceof NavigationError
        ) {
            this.dataService.changeLoadingState(false);
        }
    }

}
