import {Component} from '@angular/core';
import {DataService} from "../../../../_services/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Order} from "../../../model";
import {LocationService} from "../../../../_services/location.service";
import {AuthenticationService} from "../../../../_services/authentication.service";
import {ActionSheetController, AlertController} from "@ionic/angular";
import {LocalForageService} from '../../../../_services/local-forage.service';

@Component({
    selector: 'app-order-accept',
    templateUrl: './order-accept.component.html',
    styleUrls: ['./order-accept.component.scss'],
})
export class OrderAcceptComponent {
    reference = '';
    order: Order = null;
    pos = null;
    etas = ['In 20mins', 'In 40mins', 'In 1hr 20mins', 'In 2hrs', 'At 9AM tomorrow'];
    pendingOrdersCount = 0;
    maxAccept = 5;

    constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router,
                private authenticationService: AuthenticationService,
                public actionSheetController: ActionSheetController,
                public alertController: AlertController,
                private locationService: LocationService, private localForageService: LocalForageService) {

    }

    get authUser() {
        return AuthenticationService.authedUser();
    }

    async setupOrder() {
        this.dataService.changeLoadingState(true);
        await this.dataService.getOrder(this.reference).toPromise().then((data: any) => {
            this.order = data.order;
            this.pendingOrdersCount = data.pending_orders_count;
            this.dataService.changeLoadingState(false);
        }).catch(error => {
            this.dataService.handleNoCredentialsError(error);
        });
        let response;
        await this.localForageService.getItem('hilyfe', 'setting').then(r => {
            response = r;
        });
        if (response && response.data) {
            this.maxAccept = response.data.delivery.max;
        }
    }

    ionViewDidEnter() {
        this.reference = this.route.snapshot.queryParams.reference;
        this.setupOrder();
        this.locationService.getPosition().then(pos => this.pos = pos).catch(() => {

        });
    }

    async provideETA() {
        const buttons = [];
        for (const eta of this.etas) {
            buttons.push({
                text: eta,
                handler: () => {
                    this.presentAlertConfirm(eta);

                }
            });
        }
        buttons.push({
            text: 'Cancel',
            role: 'destructive',
            handler: () => {
            }
        });
        const actionSheet = await this.actionSheetController.create({
            header: 'Provide an ETA',
            mode: "ios",
            buttons
        });
        await actionSheet.present();
    }

    async presentAlertConfirm(eta) {
        const alert = await this.alertController.create({
            header: `Delivery for ${this.order.user.username} ${eta.toLowerCase()}. Proceed?`,
            mode: 'ios',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'destructive',
                    handler: () => {
                    }
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.dataService.changeLoadingState(true);
                        this.dataService.acceptOrder(this.reference, eta).subscribe(
                            (response: any) => {
                                if (response && response.order) {
                                    this.order = response.order;
                                }
                            },
                            (error) => {
                                this.dataService.changeLoadingState(false);
                                if (error.status === 403) {
                                    this.dataService.showSimpleToastMessage(error.error.message, 10000, 'danger');
                                }
                            },
                            () => {
                                this.dataService.changeLoadingState(false);
                                window.location.reload();
                            });
                    }
                }
            ]
        });

        await alert.present();
    }

}
