import {Component} from '@angular/core';
import {AuthenticationService} from "../../_services/authentication.service";
import {environment} from "../../environments/environment";

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss'],
})
export class IndexComponent {
    environment = environment;
    constructor() {
    }

    get authedUser() {
        return AuthenticationService.authedUser();
    }

}
