import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LocalForageService } from "../_services/local-forage.service";
import { DataService } from "../_services/data.service";
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from "@angular/router";
import { AuthenticationService } from "../_services/authentication.service";
import { ToastController } from "@ionic/angular";
import { environment } from "../environments/environment";
export class AppComponent {
    constructor(localForageService, toastController, dataService, router) {
        this.localForageService = localForageService;
        this.toastController = toastController;
        this.dataService = dataService;
        this.router = router;
        this.loading = false;
        router.events.subscribe((routerEvent) => {
            this.checkRouterEvent(routerEvent);
        });
    }
    get authedUser() {
        return AuthenticationService.authedUser();
    }
    ngOnInit() {
        localStorage.setItem('version', environment.VERSION);
        this.dataService.getLoading().subscribe(loading => {
            this.loading = loading;
        });
        this.dataService.getSimpleToastMessage().subscribe(response => {
            this.presentToast(response.message, response.duration, response.color, response.position);
        });
    }
    presentToast(message, duration = 5000, color = 'dark', position = 'bottom') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.toast) {
                this.toast.dismiss();
            }
            this.toast = yield this.toastController.create({
                message,
                duration,
                position,
                color,
                mode: 'ios',
                cssClass: 'tabs-bottom w3-animate-bottom',
                buttons: [
                    {
                        side: 'start',
                        icon: 'notifications',
                    },
                    {
                        text: 'Dismiss',
                        role: 'cancel',
                    }
                ]
            });
            this.toast.present();
        });
    }
    checkRouterEvent(routerEvent) {
        if (routerEvent instanceof NavigationStart) {
            this.dataService.changeLoadingState(true);
        }
        if (routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationCancel ||
            routerEvent instanceof NavigationError) {
            this.dataService.changeLoadingState(false);
        }
    }
}
