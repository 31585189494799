import {ErrorHandler, Injectable} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {environment} from "../environments/environment";
import {AuthenticationService} from "../_services/authentication.service";

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

    constructor() {
        Sentry.init({
            dsn: 'https://e49075b448dd48079d0ed2508605659f@sentry.io/5168305',
            environment: environment.environmentName,
            release: environment.VERSION,
            autoSessionTracking: true
        });
    }

    handleError(error) {
        try {
            Sentry.configureScope((scope) => {
                scope.setTag("hilyfe_version", environment.VERSION);
                if (AuthenticationService.authedUser()) {
                    scope.setUser({
                        username: AuthenticationService.authedUser().user.username,
                        email: AuthenticationService.authedUser().user.email,
                    });
                }
            });
        } catch (e) {

        }
        try {
            Sentry.captureException(error.originalError || error);
        } catch (e) {

        }
    }
}


export function SentryHandlerFactory() {
    if (environment.production) {
        try {
            return new SentryErrorHandler();
        } catch (e) {
            return new ErrorHandler();
        }
    }
    return new ErrorHandler();
}
