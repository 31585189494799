<ion-header [translucent]="true" class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="close()">Close</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
    <div class="w3-padding">
        <form [formGroup]="form">
            <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Method</mat-label>
                <mat-select formControlName="method">
                    <mat-option *ngFor="let method of paymentMethods" [value]="method">
                        {{method[0].toUpperCase() + method.slice(1)}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Amount</mat-label>
                <input formControlName="amount" matInput type="number" [max]="remainingAmount">
            </mat-form-field>
            <mat-error *ngIf="form.controls.amount.hasError('max')" class="w3-margin-bottom">
                Amount should be less than
                <ng-container *ngIf="defaultAmount !== 0"> or equal to</ng-container>
                ${{remainingAmount}}
            </mat-error>
            <mat-error *ngIf="form.controls.amount.hasError('isBanned')" class="w3-margin-bottom">
                Split payment can not be order total of ${{total}}. Select single if only one payment was used
            </mat-error>
        </form>
        <ion-button (click)="add()"
                    [disabled]="!form.value.amount || !form.value.method || !form.valid"
                    color="primary"
                    expand="block" shape="round">
            Add
        </ion-button>
    </div>
</ion-content>
