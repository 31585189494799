import { Routes } from '@angular/router';
import { AuthGuard } from "../_guards/auth.guard";
import { IndexComponent } from "./index/index.component";
import { NotAuthGuard } from "../_guards/not-auth.guard";
import { UserApprovedGuard } from "../_guards/user-approved.guard";
import { OrderAcceptComponent } from "./tabs/order/order-accept/order-accept.component";
import { DeliveryGuard } from "../_guards/delivery.guard";
const ɵ0 = () => import("./account/account.module.ngfactory").then(m => m.AccountModuleNgFactory), ɵ1 = () => import("./location/location.module.ngfactory").then(m => m.LocationModuleNgFactory), ɵ2 = () => import("./tabs/tabs.module.ngfactory").then(m => m.TabsPageModuleNgFactory);
const routes = [
    {
        path: 'welcome',
        component: IndexComponent,
        canActivate: [NotAuthGuard]
    },
    {
        path: 'orders/accept',
        component: OrderAcceptComponent,
        canActivate: [AuthGuard, UserApprovedGuard, DeliveryGuard]
    },
    {
        path: 'account',
        loadChildren: ɵ0,
    },
    {
        path: 'location',
        loadChildren: ɵ1,
        canActivate: [AuthGuard, UserApprovedGuard]
    },
    {
        path: '',
        loadChildren: ɵ2,
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: '/welcome',
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
