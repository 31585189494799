/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./index.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/router";
import * as i7 from "@ionic/angular";
import * as i8 from "@angular/common";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "./index.component";
var styles_IndexComponent = [i0.styles];
var RenderType_IndexComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IndexComponent, data: {} });
export { RenderType_IndexComponent as RenderType_IndexComponent };
export function View_IndexComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "hero is-dark is-bold is-large w3-padding-64"], ["style", "background-image: url('../../assets/images/bg-confetti.svg');min-height: 100vh;background-size: cover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "hero-body bgoverlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "mat-card", [["class", "w3-center w3-container w3-content w3-white w3-padding-large w3-padding-32 mat-card"], ["style", "max-width: 500px"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(3, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "h1", [["class", "w3-xlarge w3-margin-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, 0, 4, "button", [["class", "w3-block w3-padding"], ["color", "primary"], ["mat-raised-button", ""], ["queryParamsHandling", "preserve"], ["routerLink", "/account/register"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(7, 16384, null, 0, i6.RouterLink, [i6.Router, i6.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { queryParamsHandling: [0, "queryParamsHandling"], routerLink: [1, "routerLink"] }, null), i1.ɵdid(8, 737280, null, 0, i7.RouterLinkDelegate, [i8.LocationStrategy, i7.NavController, i1.ElementRef, i6.Router, [2, i6.RouterLink]], null, null), i1.ɵdid(9, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Create an account "])), (_l()(), i1.ɵeld(11, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, 0, 4, "button", [["class", "w3-block w3-padding"], ["color", "primary"], ["mat-stroked-button", ""], ["queryParamsHandling", "preserve"], ["routerLink", "/account/login"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(14, 16384, null, 0, i6.RouterLink, [i6.Router, i6.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { queryParamsHandling: [0, "queryParamsHandling"], routerLink: [1, "routerLink"] }, null), i1.ɵdid(15, 737280, null, 0, i7.RouterLinkDelegate, [i8.LocationStrategy, i7.NavController, i1.ElementRef, i6.Router, [2, i6.RouterLink]], null, null), i1.ɵdid(16, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.FocusMonitor, [2, i4.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Login "])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var currVal_4 = "preserve"; var currVal_5 = "/account/register"; _ck(_v, 7, 0, currVal_4, currVal_5); _ck(_v, 8, 0); var currVal_6 = "primary"; _ck(_v, 9, 0, currVal_6); var currVal_9 = "preserve"; var currVal_10 = "/account/login"; _ck(_v, 14, 0, currVal_9, currVal_10); _ck(_v, 15, 0); var currVal_11 = "primary"; _ck(_v, 16, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.environment.APP_NAME; _ck(_v, 5, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 9).disabled || null); var currVal_3 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_7 = (i1.ɵnov(_v, 16).disabled || null); var currVal_8 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_7, currVal_8); }); }
export function View_IndexComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-index", [], null, null, null, View_IndexComponent_0, RenderType_IndexComponent)), i1.ɵdid(1, 49152, null, 0, i11.IndexComponent, [], null, null)], null, null); }
var IndexComponentNgFactory = i1.ɵccf("app-index", i11.IndexComponent, View_IndexComponent_Host_0, {}, {}, []);
export { IndexComponentNgFactory as IndexComponentNgFactory };
