import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {LocalForageService} from "./local-forage.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../environments/environment";
import {
    CartItem,
    Deliveryman,
    NewOrder,
    OptimizedRoutesServerResponse,
    ProductResponse,
    ProductVariant,
    RequestApprovalServerResponse,
    Settings,
    SimpleToastMessage,
    StripeResponse
} from "../app/model";
import {Router} from "@angular/router";
import {
    InventoryAssignmentResponse,
    InventoryAssignmentsResponse,
    ProductBatchesResponse
} from '../app/tabs/profile/inventory-assignment/inventory-assignment.model';
import {getStoreData} from "../app/utils";

@Injectable()
export class DataService {
    private cartSize = new BehaviorSubject<number>(0);
    private cartWeight = new BehaviorSubject<object>({weight: 0.0, dist: {}});
    private loading = new BehaviorSubject<boolean>(false);
    private simpleToastMessage = new Subject<SimpleToastMessage>();
    private pinnedLocation = new Subject<object>();
    private pendingOrderCard = new Subject<object>();

    constructor(
        private localForageService: LocalForageService,
        private http: HttpClient,
        private router: Router,
    ) {
    }

    getCartSize() {
        return this.cartSize.asObservable();
    }

    getCartWeight() {
        return this.cartWeight.asObservable();
    }

    getLoading() {
        return this.loading.asObservable();
    }

    getSimpleToastMessage() {
        return this.simpleToastMessage.asObservable();
    }

    ping(): Observable<Settings> {
        return this.http.get<Settings>(`${environment.apiServer}/ping/`);
    }

    async updateCartSize() {
        let weight = 0;
        const cart: CartItem[] = (await this.localForageService.getItem('hilyfe', 'cart') || {data: []}).data;
        this.cartSize.next(cart.length);

        const dists = {};
        await cart.forEach(item => {
            weight += parseFloat(item.variant.product_size.max) * item.quantity;


            const cartDist = dists[item.variant.slug];

            if (cartDist) {
                cartDist.push(item.quantity);
            } else {
                dists[item.variant.slug] = [item.quantity];
            }
        });
        this.cartWeight.next({weight, dists});
    }

    getCollections() {
        return this.http.get(`${environment.apiServer}/collections/`);
    }

    getOrders() {
        return this.http.get(`${environment.apiServer}/orders/new-and-assigned/`);
    }

    getPendingOrders() {
        return this.http.get(`${environment.apiServer}/orders/pending/`);
    }

    getSuggestions(s) {
        return this.http.get(`${environment.apiServer}/location/autocomplete/?s=${s}`);
    }

    changeLoadingState(state) {
        this.loading.next(state);
    }

    showSimpleToastMessage(message, duration = null, color = 'dark', position: 'top' | 'bottom' | 'middle' = 'bottom') {
        this.simpleToastMessage.next({message, duration, color, position});
    }

    getPinnedLocation() {
        return this.pinnedLocation.asObservable();
    }

    getPlaceDetails(placeID) {
        return this.http.get(`${environment.apiServer}/location/place-details/?place_id=${placeID}`);
    }

    reverseGeocoding(lat, lng) {
        return this.http.get(`${environment.apiServer}/location/reverse-geocoding/?lat=${lat}&lng=${lng}`);
    }

    updatePinnedLocation(pinnedLocation) {
        this.pinnedLocation.next(pinnedLocation);
    }

    saveLocationDetails(formattedAddress, lat, lng, comment, locationFor) {
        return this.http.post(`${environment.apiServer}/location/save-details/`, {
            formatted_address: formattedAddress,
            lat,
            lng,
            comment,
            location_for: locationFor
        });
    }

    getDeliveryLocations() {
        return this.http.get(`${environment.apiServer}/location/`);
    }

    orderFulfilled(reference, method) {
        return this.http.post(`${environment.apiServer}/orders/fulfilled/`, {
            reference,
            method,
        });
    }

    getSessionUrl(order: NewOrder): Observable<StripeResponse> {
        return this.http.post<StripeResponse>(`${environment.apiServer}/stripe_create_session/`, order);
    }

    getOrder(reference) {
        return this.http.get(`${environment.apiServer}/orders/${reference}/`);
    }

    getProduct(slug: any): Observable<ProductResponse> {
        return this.http.get<ProductResponse>(`${environment.apiServer}/product/${slug}/`);
    }

    acceptOrder(reference, eta) {
        return this.http.post(`${environment.apiServer}/orders/accept/${reference}/`, {
            eta
        });
    }

    getDistanceMatrix(origin, destination) {
        return this.http.post(`${environment.apiServer}/location/matrix/`, {
            origin, destination
        });
    }

    getProductGramMeasure(deliveryman: string, slug = null) {
        if (slug) {
            return this.http.get(`${environment.apiServer}/product-measure/${deliveryman}/${slug}/`);
        } else {
            return this.http.get(`${environment.apiServer}/product-measure/${deliveryman}/`);
        }
    }

    getEarnings(earningType) {
        return this.http.get(`${environment.apiServer}/earnings/${earningType}/`);
    }

    saveOfflineOrder(username: string, cart: CartItem[]) {
        return this.http.post(`${environment.apiServer}/orders/save-offline-order/`, {
            username,
            cart: cart.map(item => {
                return {
                    variant: item.variant.slug,
                    quantity: item.quantity,
                };
            }),
        });
    }

    generateReferralCode(username) {
        return this.http.post(`${environment.apiServer}/accounts/generate-referral-code/`, {
            username,
        });
    }

    getUsernameSuggestions(username: any) {
        return this.http.get(`${environment.apiServer}/suggest-username/?s=${username}`);
    }

    placeCashOnDeliveryOrder(order: NewOrder) {
        return this.http.post(`${environment.apiServer}/orders/cash-on-delivery-order/`, order);
    }

    validateDiscountCode(discount) {
        return this.http.post(`${environment.apiServer}/orders/validate-discount-code/`, {
            discount,
        });
    }

    getPayouts() {
        return this.http.get(`${environment.apiServer}/payouts/`);
    }

    payout(username: string) {
        return this.http.post(`${environment.apiServer}/payout/${username}/`, {});
    }

    getReferrals() {
        return this.http.get(`${environment.apiServer}/referrals/`);
    }

    getDiscounts() {
        return this.http.get(`${environment.apiServer}/discounts/`);
    }

    approveAccount(username: any, code: string) {
        return this.http.post(`${environment.apiServer}/accounts/approve/`, {
            username,
            code
        });
    }

    showPendingOrderCard(state) {
        this.pendingOrderCard.next(state);
    }

    getPendingOrderCard() {
        return this.pendingOrderCard.asObservable();
    }

    handleNoCredentialsError(error) {
        if (error.status === 401) {
            localStorage.clear();
            this.router.navigate(['welcome'], {queryParams: {next: window.location.pathname + window.location.search}});
        } else {
            return error;
        }
    }

    cancelOrder(reference, by, reason) {
        return this.http.post(`${environment.apiServer}/orders/cancel/${reference}/`, {by, reason});
    }

    getDeliverymen(): Observable<{
        deliverymen: Array<Deliveryman>
    }> {
        return this.http.get<{
            deliverymen: Array<Deliveryman>
        }>(`${environment.apiServer}/deliverymen/`);
    }

    getTerms() {
        return this.http.get(`${environment.apiServer}/terms/`);
    }

    transferOrder(reference: string, username: any) {
        return this.http.post(`${environment.apiServer}/orders/transfer/${reference}/`, {
            username
        });
    }

    getReplenishDates(date = null) {
        if (date) {
            return this.http.get(`${environment.apiServer}/replenish/${date}/`);
        } else {
            return this.http.get(`${environment.apiServer}/replenish/`);
        }
    }

    getOptimizedRoutes(): Observable<OptimizedRoutesServerResponse> {
        return this.http.get<OptimizedRoutesServerResponse>(`${environment.apiServer}/optimized-routes/`);
    }

    requestReApproval(): Observable<RequestApprovalServerResponse> {
        return this.http.post<RequestApprovalServerResponse>(`${environment.apiServer}/request-re-approval/`, {});
    }

    getProductBatches(): Observable<ProductBatchesResponse> {
        return this.http.get<ProductBatchesResponse>(`${environment.apiServer}/product-batches/`);
    }

    assignInventories(inventoryAssignments): Observable<any> {
        return this.http.post<any>(`${environment.apiServer}/inventory-assignments/new/`, {inventory_assignments: inventoryAssignments});
    }

    getInventoryAssignments(deliveryman: string = null): Observable<InventoryAssignmentsResponse> {
        const params = new HttpParams({fromObject: {deliveryman}});
        return this.http.get<InventoryAssignmentsResponse>(`${environment.apiServer}/inventory-assignments/`, {params});
    }

    getInventoryAssignment(inventoryId: number): Observable<InventoryAssignmentResponse> {
        return this.http.get<InventoryAssignmentResponse>(`${environment.apiServer}/inventory-assignments/${inventoryId}/`, {});
    }

    async cart(): Promise<CartItem[]> {
        const items = await getStoreData<CartItem[]>(this.localForageService, 'cart', []);
        if (items.length) {
            const productVariants: ProductVariant[] = await this.getVariants(items.map(item => item.variant.slug)).toPromise();
            let cart: CartItem[] = Object.create(items);
            cart = cart.map(item => {
                return {
                    variant: productVariants.find(subItem => subItem.slug === item.variant.slug),
                    quantity: item.quantity
                };
            });
            return cart;
        }
        return items;
    }

    private getVariants(slugs: string[]): Observable<ProductVariant[]> {
        return this.http.post<ProductVariant[]>(`${environment.apiServer}/variants/`, {slugs});
    }
}
