import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AuthenticationService } from "../../../../_services/authentication.service";
import { DataService } from "../../../../_services/data.service";
import { LocationService } from "../../../../_services/location.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ActionSheetController, AlertController, ModalController } from "@ionic/angular";
import { OrderFulfilledDialogComponent } from "../order-fulfilled-dialog/order-fulfilled-dialog.component";
import { environment } from "../../../../environments/environment";
export class OrderSummaryComponent {
    constructor(authenticationService, dataService, alertController, actionSheetController, modalController, locationService, bottomSheetData, sanitizer) {
        this.authenticationService = authenticationService;
        this.dataService = dataService;
        this.alertController = alertController;
        this.actionSheetController = actionSheetController;
        this.modalController = modalController;
        this.locationService = locationService;
        this.bottomSheetData = bottomSheetData;
        this.sanitizer = sanitizer;
        this.view = 'orders';
        this.embedUrl = null;
        this.showSummary = true;
    }
    get discount() {
        const amount = parseFloat(this.order.discount_applied.amount);
        if (amount) {
            return `$${this.order.discount_applied.amount}`;
        }
        return `${this.order.discount_applied.percentage}%`;
    }
    get authedUser() {
        return AuthenticationService.authedUser();
    }
    orderFulfilled(order) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: OrderFulfilledDialogComponent,
                componentProps: {
                    order
                }
            });
            yield modal.present();
            const result = yield modal.onDidDismiss();
            if (result && result.data && result.data.completed) {
                this.order.state = 'fulfilled';
                this.order.paid = true;
                this.order.mode = result.data.method;
            }
        });
    }
    ngOnInit() {
        if (this.bottomSheetData) {
            this.order = this.bottomSheetData.order;
            this.view = 'delivery';
        }
        if (this.showETADirections === undefined) {
            this.showETADirections = this.order.state !== 'fulfilled' && this.view !== 'orders';
        }
        if (this.order.state !== "fulfilled" && this.view !== 'orders') {
            this.locationService.getPosition().then(pos => {
                const origin = { lat: pos.lat, lng: pos.lng };
                const destination = { lat: this.order.delivery_location.lat, lng: this.order.delivery_location.lng };
                // tslint:disable-next-line:max-line-length
                const url = `https://www.google.com/maps/embed/v1/directions?origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&key=${environment.GOOGLE_API_KEY}`;
                this.embedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            }).catch(() => {
            });
        }
    }
    transferOrder(order) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const buttons = [];
            yield this.dataService.getDeliverymen().toPromise().then((data) => {
                this.dataService.changeLoadingState(true);
                for (const deliveryman of data.deliverymen) {
                    if (this.authedUser.user.username !== deliveryman.username) {
                        buttons.push({
                            text: deliveryman.username,
                            handler: () => {
                                this.presentAlertConfirm(order, deliveryman);
                            }
                        });
                    }
                }
            }).then(() => {
                this.dataService.changeLoadingState(false);
            });
            buttons.push({
                text: 'Cancel',
                role: 'destructive',
                handler: () => {
                }
            });
            const actionSheet = yield this.actionSheetController.create({
                header: 'Which deliveryman?',
                mode: "ios",
                buttons
            });
            yield actionSheet.present();
        });
    }
    presentAlertConfirm(order, deliveryman) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                header: `Transfer $${order.amount_payable} delivery for ${this.order.user.username} to ${deliveryman.username}?`,
                mode: 'ios',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'destructive',
                        handler: () => {
                        }
                    }, {
                        text: 'Yes',
                        handler: () => {
                            this.dataService.changeLoadingState(true);
                            this.dataService.transferOrder(order.reference, deliveryman.username).subscribe((t) => {
                                this.dataService.showSimpleToastMessage(t.message);
                            }, () => {
                                this.dataService.changeLoadingState(false);
                            }, () => {
                                this.dataService.changeLoadingState(false);
                            });
                        }
                    }
                ]
            });
            yield alert.present();
        });
    }
    cancelOrder(reference) {
        this.dataService.changeLoadingState(true);
        this.dataService.cancelOrder(reference, this.authedUser.user.username, '').subscribe((response) => {
            this.dataService.showSimpleToastMessage(response.message);
            this.dataService.changeLoadingState(false);
            window.location.reload();
        }, () => {
            this.dataService.changeLoadingState(false);
        });
    }
    showMoreOptions(order) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const buttons = [];
            if (this.order.state === 'pending' && this.order.deliveryman_assigned &&
                this.order.deliveryman_assigned.username === this.authedUser.user.username &&
                this.view === 'delivery') {
                buttons.push({
                    text: 'Transfer to another deliveryman',
                    handler: () => {
                        this.transferOrder(order);
                    }
                });
            }
            if (this.authenticationService.permissions.canCancelOrders && this.order.state !== 'cancelled') {
                buttons.push({
                    text: 'Cancel order',
                    handler: () => {
                        this.cancelOrder(order.reference);
                    }
                });
            }
            buttons.push({
                text: 'Dismiss',
                role: 'destructive',
                handler: () => {
                }
            });
            const actionSheet = yield this.actionSheetController.create({
                header: 'Options',
                mode: "ios",
                buttons
            });
            yield actionSheet.present();
        });
    }
}
