import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler) {

        // tslint:disable-next-line
        let newParams = new HttpParams({fromString: request.params.toString()});
        const requestClone = request.clone({
            params: newParams
        });
        return next.handle(requestClone);
    }
}
