import { HttpClient } from '@angular/common/http';
import { catchError, map, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { DataService } from './data.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from "../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "./data.service";
import * as i4 from "@auth0/angular-jwt";
export class AuthenticationService {
    constructor(http, router, dataService, helper) {
        this.http = http;
        this.router = router;
        this.dataService = dataService;
        this.helper = helper;
        this.permissions = {
            inventoryManager: this.isInventoryManager(),
            canCancelOrders: this.canCancelOrders(),
            deliveryman: this.isDeliveryman(),
            ambassador: this.isAmbassador(),
            admin: this.isAdmin(),
        };
        this.apiServer = environment.apiServer;
        AuthenticationService.jwtHelper = helper;
    }
    static clearLocalStorage() {
        try {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('TOKEN');
        }
        catch (e) {
        }
    }
    static authedUser() {
        try {
            const token = localStorage.getItem('TOKEN');
            const tokenExpired = AuthenticationService.jwtHelper.isTokenExpired(token);
            if (!tokenExpired) {
                return JSON.parse(localStorage.getItem('currentUser'));
            }
            AuthenticationService.clearLocalStorage();
            return null;
        }
        catch (e) {
            return null;
        }
    }
    currentUser() {
        try {
            const token = localStorage.getItem('TOKEN');
            const tokenExpired = this.helper.isTokenExpired(token);
            if (!tokenExpired) {
                return JSON.parse(localStorage.getItem('currentUser'));
            }
            AuthenticationService.clearLocalStorage();
            return null;
        }
        catch (e) {
            return null;
        }
    }
    login(username, password) {
        return this.http
            .post(`${this.apiServer}/accounts/login/`, {
            username,
            password
        })
            .pipe(retry(2), map((authenticatedObject) => {
            if (authenticatedObject && authenticatedObject.token) {
                const userObject = authenticatedObject.user;
                const token = authenticatedObject.token;
                localStorage.setItem('TOKEN', token);
                localStorage.setItem('currentUser', JSON.stringify(userObject));
            }
            return authenticatedObject.user;
        }));
    }
    // tslint:disable-next-line:variable-name
    register(email, password, whatsapp_number, referral1) {
        return this.http
            .post(`${this.apiServer}/accounts/signup/`, {
            email,
            whatsapp_number,
            referral1,
            password1: password,
            password2: password,
        })
            .pipe(catchError((err) => {
            throw Error(JSON.stringify(err.error));
        }), map((authenticatedObject) => {
            if (authenticatedObject && authenticatedObject.token) {
                const userObject = authenticatedObject.user;
                const token = authenticatedObject.token;
                localStorage.setItem('TOKEN', token);
                localStorage.setItem('currentUser', JSON.stringify(userObject));
            }
            return authenticatedObject.user;
        }));
    }
    // tslint:disable-next-line:variable-name
    join(username, old_password, email, whatsapp_number, new_password1) {
        return this.http
            .post(`${this.apiServer}/accounts/join/`, {
            username,
            old_password,
            email,
            whatsapp_number,
            new_password1,
            new_password2: new_password1,
        })
            .pipe(catchError((err) => {
            throw Error(JSON.stringify(err.error));
        }), map((authenticatedObject) => {
            if (authenticatedObject && authenticatedObject.token) {
                const userObject = authenticatedObject.user;
                const token = authenticatedObject.token;
                localStorage.setItem('TOKEN', token);
                localStorage.setItem('currentUser', JSON.stringify(userObject));
            }
            return authenticatedObject.user;
        }));
    }
    resetPassword(email) {
        return this.http
            .post(`${this.apiServer}/accounts/password/reset/`, {
            email,
        }).pipe(retry(1));
    }
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        }
        else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }
    changePassword(uid, token, password1, password2) {
        return this.http
            .post(`${this.apiServer}/accounts/password/reset/confirm/`, {
            uid,
            token,
            new_password1: password1,
            new_password2: password2,
        }).pipe(retry(2));
    }
    // tslint:disable-next-line:variable-name
    changeCurrentPassword(old_password, new_password1, new_password2) {
        return this.http.post(`${environment.apiServer}/accounts/password/change/`, {
            old_password,
            new_password1,
            new_password2,
        });
    }
    // tslint:disable-next-line:variable-name
    changeDetails(email, whatsapp_number) {
        return this.http
            .post(`${this.apiServer}/accounts/change-details/`, {
            email,
            whatsapp_number,
        }).pipe(retry(2), catchError(this.handleError));
    }
    isAdmin() {
        return this.currentUser() ? this.currentUser().user.is_herre : false;
    }
    isDeliveryman() {
        return this.currentUser() ? this.currentUser().profile_type === 'deliveryman' : false;
    }
    isAmbassador() {
        return this.currentUser() ? this.currentUser().is_ambassador : false;
    }
    canCancelOrders() {
        return this.currentUser() ? (this.currentUser().permissions.includes('cancel-pending-orders') || this.isAdmin()) : false;
    }
    isInventoryManager() {
        return this.currentUser() ? this.currentUser().permissions.includes('inventory-manager') : false;
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.DataService), i0.ɵɵinject(i4.JwtHelperService)); }, token: AuthenticationService, providedIn: "root" });
