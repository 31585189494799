/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@ionic/angular";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "./app.component";
import * as i9 from "../_services/local-forage.service";
import * as i10 from "../_services/data.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "w3-animate-opacity trans-400 mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "position: fixed;width: 100%;z-index: 1000;top: 0"]], [[2, "w3-hide", null], [1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "ion-router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i5.IonRouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], [8, null], i5.Config, i5.NavController, i7.Location, i1.ElementRef, i6.Router, i1.NgZone, i6.ActivatedRoute, [3, i5.IonRouterOutlet]], null, null)], function (_ck, _v) { var currVal_4 = "indeterminate"; _ck(_v, 1, 0, currVal_4); _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; var currVal_1 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_2 = i1.ɵnov(_v, 1).mode; var currVal_3 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i8.AppComponent, [i9.LocalForageService, i5.ToastController, i10.DataService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
