import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map, retry} from 'rxjs/operators';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';
import {DataService} from './data.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {environment} from "../environments/environment";
import {AuthUser} from "../app/model";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    static jwtHelper;
    permissions = {
        inventoryManager: this.isInventoryManager(),
        canCancelOrders: this.canCancelOrders(),
        deliveryman: this.isDeliveryman(),
        ambassador: this.isAmbassador(),
        admin: this.isAdmin(),
    };
    private apiServer = environment.apiServer;

    constructor(
        private http: HttpClient,
        private router: Router,
        private dataService: DataService,
        private helper: JwtHelperService) {
        AuthenticationService.jwtHelper = helper;
    }

    static clearLocalStorage() {
        try {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('TOKEN');
        } catch (e) {

        }
    }

    static authedUser(): AuthUser | null {
        try {
            const token = localStorage.getItem('TOKEN');
            const tokenExpired = AuthenticationService.jwtHelper.isTokenExpired(token);
            if (!tokenExpired) {
                return JSON.parse(localStorage.getItem('currentUser'));
            }
            AuthenticationService.clearLocalStorage();
            return null;
        } catch (e) {
            return null;
        }
    }

    currentUser(): AuthUser | null {
        try {
            const token = localStorage.getItem('TOKEN');
            const tokenExpired = this.helper.isTokenExpired(token);
            if (!tokenExpired) {
                return JSON.parse(localStorage.getItem('currentUser'));
            }
            AuthenticationService.clearLocalStorage();
            return null;
        } catch (e) {
            return null;
        }
    }

    login(username: string, password: string) {
        return this.http
            .post<any>(`${this.apiServer}/accounts/login/`, {
                username,
                password
            })
            .pipe(
                retry(2),
                map((authenticatedObject) => {
                    if (authenticatedObject && authenticatedObject.token) {
                        const userObject: object = authenticatedObject.user;
                        const token: string = authenticatedObject.token;
                        localStorage.setItem('TOKEN', token);
                        localStorage.setItem('currentUser', JSON.stringify(userObject));
                    }
                    return authenticatedObject.user;
                })
            );
    }

    // tslint:disable-next-line:variable-name
    register(email: string, password: string, whatsapp_number: string, referral1): any {
        return this.http
            .post<any>(`${this.apiServer}/accounts/signup/`, {
                email,
                whatsapp_number,
                referral1,
                password1: password,
                password2: password,
            })
            .pipe(
                catchError((err) => {
                    throw Error(JSON.stringify(err.error));
                }),
                map((authenticatedObject) => {
                    if (authenticatedObject && authenticatedObject.token) {
                        const userObject: object = authenticatedObject.user;
                        const token: string = authenticatedObject.token;
                        localStorage.setItem('TOKEN', token);
                        localStorage.setItem('currentUser', JSON.stringify(userObject));
                    }
                    return authenticatedObject.user;
                })
            );
    }

    // tslint:disable-next-line:variable-name
    join(username, old_password, email, whatsapp_number, new_password1): any {
        return this.http
            .post<any>(`${this.apiServer}/accounts/join/`, {
                username,
                old_password,
                email,
                whatsapp_number,
                new_password1,
                new_password2: new_password1,
            })
            .pipe(
                catchError((err) => {
                    throw Error(JSON.stringify(err.error));
                }),
                map((authenticatedObject) => {
                    if (authenticatedObject && authenticatedObject.token) {
                        const userObject: object = authenticatedObject.user;
                        const token: string = authenticatedObject.token;
                        localStorage.setItem('TOKEN', token);
                        localStorage.setItem('currentUser', JSON.stringify(userObject));
                    }
                    return authenticatedObject.user;
                })
            );
    }

    resetPassword(email: string) {
        return this.http
            .post<any>(`${this.apiServer}/accounts/password/reset/`, {
                email,
            }).pipe(
                retry(1),
            );

    }

    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
    }

    changePassword(uid, token, password1, password2) {
        return this.http
            .post<any>(`${this.apiServer}/accounts/password/reset/confirm/`, {
                uid,
                token,
                new_password1: password1,
                new_password2: password2,
            }).pipe(
                retry(2),
            );
    }

    // tslint:disable-next-line:variable-name
    changeCurrentPassword(old_password, new_password1, new_password2) {
        return this.http.post(`${environment.apiServer}/accounts/password/change/`, {
            old_password,
            new_password1,
            new_password2,
        });
    }

    // tslint:disable-next-line:variable-name
    changeDetails(email: any, whatsapp_number: string) {
        return this.http
            .post<any>(`${this.apiServer}/accounts/change-details/`, {
                email,
                whatsapp_number,
            }).pipe(
                retry(2),
                catchError(this.handleError)
            );
    }

    private isAdmin() {
        return this.currentUser() ? this.currentUser().user.is_herre : false;
    }

    private isDeliveryman() {
        return this.currentUser() ? this.currentUser().profile_type === 'deliveryman' : false;
    }

    private isAmbassador() {
        return this.currentUser() ? this.currentUser().is_ambassador : false;
    }

    private canCancelOrders() {
        return this.currentUser() ? (this.currentUser().permissions.includes('cancel-pending-orders') || this.isAdmin()) : false;
    }

    private isInventoryManager() {
        return this.currentUser() ? this.currentUser().permissions.includes('inventory-manager') : false;
    }
}
