import * as tslib_1 from "tslib";
import { PromoType } from "./model";
export function getCartAmount(cart, humanize = false) {
    let amount = 0;
    for (const item of cart) {
        let amountToAdd = item.quantity * parseFloat(item.variant.price);
        if (item.variant.promo) {
            const promoConfig = item.variant.promo.config;
            if (promoConfig.type === PromoType.BULK_DISCOUNT) {
                if (promoConfig.applicable_quantities.includes(item.quantity) || promoConfig.applicable_quantities.length === 0) {
                    amountToAdd = (parseFloat(item.variant.price) * (1 - (promoConfig.percentage_discount / 100))) * item.quantity;
                }
            }
        }
        amount += amountToAdd;
    }
    return humanize ? amount.toFixed(2) : amount;
}
export function getStoreData(store, key, fallback) {
    return tslib_1.__awaiter(this, void 0, void 0, function* () {
        return ((yield store.getItem('hilyfe', key)) || { data: fallback }).data;
    });
}
export function setStoreData(store, key, data) {
    store.setItem('hilyfe', key, data);
}
