<div class="hero is-dark is-bold is-large w3-padding-64"
     style="background-image: url('../../assets/images/bg-confetti.svg');min-height: 100vh;background-size: cover">
    <div class="hero-body bgoverlay">
        <mat-card class="w3-center w3-container w3-content w3-white w3-padding-large w3-padding-32"
                  style="max-width: 500px">
            <h1 class="w3-xlarge w3-margin-bottom">
                {{environment.APP_NAME}}
            </h1>
            <button class="w3-block w3-padding" color="primary" mat-raised-button queryParamsHandling="preserve"
                    routerLink="/account/register">Create
                an
                account
            </button>
            <br>
            <br>
            <button class="w3-block w3-padding" color="primary" mat-stroked-button queryParamsHandling="preserve"
                    routerLink="/account/login">Login
            </button>
            <!--<br>-->
            <!--<br>-->
            <!--<a (click)="htmliFrameElement.classList.toggle('w3-hide')"-->
            <!--   style="border-radius: 0;">How to add {{ APP_NAME }} to your homescreen-->
            <!--</a>-->
            <!--<div #htmliFrameElement class="w3-hide w3-animate-bottom">-->
            <!--    <br>-->
            <!--    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen-->
            <!--frameborder="0" height="315"-->
            <!--width="100%"></iframe>-->
            <!--</div>-->
        </mat-card>
        <br>
        <br>
    </div>
</div>

