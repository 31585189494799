import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from "../_services/authentication.service";
import {DataService} from "../_services/data.service";
import {environment} from '../environments/environment';

@Injectable()
export class HoursOfOperationGuard implements CanActivate {

    constructor(private router: Router, private authenticationService: AuthenticationService, private dataService: DataService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const dt = new Date();

        const startTime = '05:59:59';
        const endTime = '20:59:59';

        const s = startTime.split(':');
        const dt1 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(),
            parseInt(s[0]), parseInt(s[1]), parseInt(s[2]));

        const e = endTime.split(':');
        const dt2 = new Date(dt.getFullYear(), dt.getMonth(),
            dt.getDate(), parseInt(e[0]), parseInt(e[1]), parseInt(e[2]));
        if ((dt >= dt1 && dt <= dt2) || !environment.production || this.authenticationService.permissions.admin) {
            return true;
        } else {
            this.dataService.showSimpleToastMessage('Orders can only be placed between 9AM and 9PM', 5000, 'danger');
            return false;
        }
    }
}
