import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from "../_services/authentication.service";

@Injectable()
export class UserApprovedGuard implements CanActivate {

    constructor(private router: Router, private authenticationService: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        try {
            if (this.authenticationService.currentUser() && this.authenticationService.currentUser().is_approved) {
                return true;
            } else {
                this.router.navigate(['/tabs/profile']);
                return false;
            }
        } catch (e) {
            return false;
        }

    }
}
