<ion-header [translucent]="true" class="ion-no-border">
    <ion-toolbar>
        <ion-title>
            New Order
        </ion-title>
        <ion-buttons slot="start">
            <ion-back-button defaultHref="/tabs/delivery"></ion-back-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
    <div class="w3-container w3-content">
        <ng-container
            *ngIf="order && order.state === 'cancelled'">
            <h1 class="w3-center w3-padding-64">This order was cancelled.</h1>
        </ng-container>
        <ng-container
            *ngIf="order && order.deliveryman_assigned && order.deliveryman_assigned.username !== authUser.user.username">
            <h1 class="w3-center w3-padding-64">This order is currently assigned to another deliveryman.</h1>
        </ng-container>

        <app-order-summary
            [showSummary]="!!order?.deliveryman_assigned"
            *ngIf="order"
            [order]="order"
            view="delivery">
            <div *ngIf="order?.deliveryman_assigned === null && order.state !== 'cancelled'"
                 accept>
                <ion-button (click)="provideETA()" color="primary"
                            *ngIf="pendingOrdersCount < maxAccept"
                            expand="block">
                    Accept order
                </ion-button>
                <div *ngIf="pendingOrdersCount >= maxAccept"
                     class="w3-red w3-padding-large w3-center w3-round-xlarge">
                    You can only accept new order when you have less than {{maxAccept}} pending orders.
                </div>
            </div>
        </app-order-summary>
    </div>
</ion-content>
