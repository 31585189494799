import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {DataService} from "../_services/data.service";
import {LocalForageService} from "../_services/local-forage.service";

@Injectable()
export class RestrictCheckoutGuard implements CanActivate {

    constructor(private router: Router, private localForageService: LocalForageService, private dataService: DataService) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let response;
        await this.localForageService.getItem('hilyfe', 'setting').then(r => {
            response = r;
        });
        if (response && response.data.restrictions.restrict_checkout === false) {
            return true;
        } else {
            this.dataService.showSimpleToastMessage(response.data.restrictions.restrict_checkout_message, 5000, 'danger');
            return false;
        }
    }
}
