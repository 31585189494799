<ion-header [translucent]="true" class="ion-no-border">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="close()">Close</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
    <ion-list style="padding: 0">
        <ion-item lines="none">
            <h1 class="w3-xlarge">Delivery for {{order.user.username}} checklist</h1>
        </ion-item>
        <ion-item *ngFor="let item of orderCheckList">
            <ion-label>
                <span class="w3-large w3-block">{{item.product_size__size * item.quantity}} units</span>
                <span class="menu-label is-marginless">{{item.desc}}</span>
            </ion-label>
            <ion-checkbox [(ngModel)]="item.checked" mode="ios" slot="start"></ion-checkbox>
        </ion-item>
        <ion-item>
            <ion-label class="w3-large">
                <span class="w3-large w3-block">${{order.earning}} earned</span>
                <span class="menu-label is-marginless">{{order.deliveryman_assigned.username}}</span>
            </ion-label>
            <ion-checkbox [(ngModel)]="earnings" mode="ios" slot="start"></ion-checkbox>
        </ion-item>
    </ion-list>
    <ion-segment (ionChange)="styleChanged($event)" [value]="selectedStyle">
        <ion-segment-button [value]="PaymentStyle.Single">
            <ion-label>Single</ion-label>
        </ion-segment-button>
        <ion-segment-button [disabled]="order.payment_method==='stripe'" [value]="PaymentStyle.Multiple">
            <ion-label>Multiple</ion-label>
        </ion-segment-button>
    </ion-segment>
    <ion-list *ngIf="selectedStyle===PaymentStyle.Single" style="padding: 0">
        <ion-radio-group [value]="selectedPaymentMethod">
            <ion-item lines="none" *ngFor="let method of order.available_payment_methods">
                <ion-label>{{method|titlecase}}</ion-label>
                <ion-radio (click)="selectedPaymentMethod=method"
                           [disabled]="order.payment_method.toLowerCase().includes('stripe') || method.toLocaleLowerCase().includes('stripe')"
                           [value]="method" mode="ios" slot="start"></ion-radio>
            </ion-item>
        </ion-radio-group>
    </ion-list>
    <div *ngIf="selectedStyle===PaymentStyle.Multiple">
        <ion-item *ngFor="let payment of splitPayments">
            <ion-label class="w3-large">
                <span class="w3-large w3-block">${{payment.amount}}</span>
                <span class="menu-label is-marginless">{{payment.method}}</span>
            </ion-label>
            <ion-icon name="close-outline" slot="end" (click)="removeSplitPayment(payment)"></ion-icon>
        </ion-item>
        <div class="w3-center" *ngIf="!disablePaymentSplitting">
            <ion-button class="w3-margin"
                        shape="round"
                        (click)="addSplitPayment()">Add split payment
            </ion-button>
        </div>
    </div>
    <ion-button (click)="confirmFulfilledOrder()"
                [disabled]="!hasPaymentMethodBeenSelected || !earnings || !orderSummaryChecked || loading"
                [fill]="!hasPaymentMethodBeenSelected || !earnings || !orderSummaryChecked? 'outline': 'solid'"
                class="w3-margin"
                color="primary"
                expand="block"
                shape="round">Confirm fulfillment ✓
    </ion-button>


</ion-content>


