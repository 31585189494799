import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from "../_services/authentication.service";

@Injectable()
export class HerreDeliveryGuard implements CanActivate {

    constructor(private router: Router, private authenticationService: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        try {
            if (this.authenticationService.currentUser().user.is_herre || this.authenticationService.currentUser().profile_type === 'deliveryman') {
                return true;
            } else {
                this.router.navigate(['/tabs/profile']);
                return false;
            }
        } catch (e) {
            return false;
        }
    }
}
