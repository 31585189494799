import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "../_guards/auth.guard";
import {IndexComponent} from "./index/index.component";
import {NotAuthGuard} from "../_guards/not-auth.guard";
import {UserApprovedGuard} from "../_guards/user-approved.guard";
import {OrderAcceptComponent} from "./tabs/order/order-accept/order-accept.component";
import {DeliveryGuard} from "../_guards/delivery.guard";

const routes: Routes = [
    {
        path: 'welcome',
        component: IndexComponent,
        canActivate: [NotAuthGuard]
    },
    {
        path: 'orders/accept',
        component: OrderAcceptComponent,
        canActivate: [AuthGuard, UserApprovedGuard, DeliveryGuard]
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    },
    {
        path: 'location',
        loadChildren: () => import('./location/location.module').then(m => m.LocationModule),
        canActivate: [AuthGuard, UserApprovedGuard]
    },
    {
        path: '',
        loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: '/welcome',
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes,
            {
                preloadingStrategy: PreloadAllModules,
                onSameUrlNavigation: 'reload'
            })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
