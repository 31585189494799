import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { DataService } from "../_services/data.service";
import { LocalForageService } from "../_services/local-forage.service";
export class RestrictCheckoutGuard {
    constructor(router, localForageService, dataService) {
        this.router = router;
        this.localForageService = localForageService;
        this.dataService = dataService;
    }
    canActivate(route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let response;
            yield this.localForageService.getItem('hilyfe', 'setting').then(r => {
                response = r;
            });
            if (response && response.data.restrictions.restrict_checkout === false) {
                return true;
            }
            else {
                this.dataService.showSimpleToastMessage(response.data.restrictions.restrict_checkout_message, 5000, 'danger');
                return false;
            }
        });
    }
}
