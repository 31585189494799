import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrderSummaryComponent} from "./order-summary.component";
import {MatButtonModule} from "@angular/material/button";
import {MatChipsModule} from "@angular/material/chips";
import {IonicModule} from "@ionic/angular";
import {RouterModule} from "@angular/router";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatDividerModule} from "@angular/material/divider";
import {MatMenuModule} from '@angular/material/menu';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetModule} from '@angular/material/bottom-sheet';


@NgModule({
    declarations: [OrderSummaryComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatChipsModule,
        IonicModule,
        RouterModule.forChild([]),
        MatProgressBarModule,
        MatDividerModule,
        MatMenuModule,
        MatBottomSheetModule
    ],
    providers: [
        {provide: MAT_BOTTOM_SHEET_DATA, useValue: null},
    ],
    exports: [
        OrderSummaryComponent
    ],
    entryComponents: [OrderSummaryComponent]
})
export class OrderSummaryModule {
}
