export interface Discount {
    percentage: number;
    reference_code: string;
    used: boolean;
    amount: string;
    reason: string;
}

export interface Order {
    earning: number;
    created: string;
    discount_applied?: Discount;
    payment_method: string;
    phone_number: string;
    eta: string;
    mode: 'offline' | 'online' | 'online_cash';
    user: {
        email: string;
        phone_number: string;
        username: string
    };
    paid: boolean;
    reference: string;
    amount_payable: string;
    summary: {
        product_variant: ProductVariant
        quantity: number;
        free_quantity: number
        price: number;
    }[];
    deliveryman_assigned?: {
        phone_number: any;
        username: string
    };
    id: number;
    available_payment_methods: Array<string>;
    delivery_location: {
        uuid: string;
        comment: string;
        formatted_address: string;
        lat: string;
        lng: string;
    };
    state: 'pending' | 'fulfilled' | 'cancelled';
}

export interface NewOrder {
    location: string;
    phone: string;
    override_profile_phone: boolean;
    discount: string;
    cart: CartItem[];
    addons: CartItem[];
}

export interface ProductMeasureOrder {
    deliveryman: string;
    title: string;
    created: number;
    sold: number;
    size: string;
    amount: number;
}

export interface Product {
    is_addon: boolean;
    addon_max_purchasable: string;
    available: string;
    unit: string;
    description: string;
    is_active: boolean;
    photo: any;
    title: string;
    slug: string;
    variants: Array<ProductVariant>;
    orders?: ProductMeasureOrder[];
}

export interface ProductMeasure extends Product {
    pending: number;
    total: number;
    sold: number;
    total_sales: number;
}

export interface ProductVariant {
    product__title: string;
    product_size: ProductSize;
    price: string;
    promo?: Promo;
    slug: string;
    photo: string;
}

export interface ProductSize {
    size: string;
    title: string;
    max: string;
}

export interface AuthUser {
    has_requested_re_approval: boolean;
    disabled_for_inactivity: boolean;
    permissions: Array<string>;
    eligible_for_discount_codes: boolean;
    eligible_for_referral_codes: boolean;
    is_herre: boolean;
    user: {
        username: string
        email: string
        is_herre: boolean
    };
    profile_type: 'deliveryman' | 'member';
    referral_code: string;
    whatsapp_number: string;
    is_approved: boolean;
    is_ambassador: boolean;
}

export interface Earning {
    detail: {
        description: any;
        cost: string;
        earning_percentage: string,
    };
    order: Order;
    paid_out: boolean;
    amount: number;
}

export interface Collection {
    title: string;
    description: string;
    products: Array<Product>;
}

export interface Payout {
    earnings: Array<Earning>;
    amount: string;
    paid: boolean;
}

export interface DeliverymanPayout {
    username: string;
    payouts: Array<Payout>;
}

export interface Deliveryman {
    username: string;
}


export interface Referral {
    count: string;
    code: string;
    used_by: string;
    used: boolean;
}

export interface SimpleToastMessage {
    message: string;
    color: string;
    duration: number | null;
    position: 'top' | 'bottom' | 'middle';
}

export interface Term {
    title: string;
    slug: string;
    content: string;
    description: string;
    updated: string;
}

export interface OptimizedRoute {
    order: Order;
    data: {
        end_address: string;
        distance: {
            text: string;
        };
        duration: {
            text: string;
        };
        start_address: string;
    };
    position: number;
}

export interface OptimizedRoutesServerResponse {
    end: string;
    start: string;
    routes: Array<OptimizedRoute>;
}

export interface RequestApprovalServerResponse {
    message: string;
}

export interface Settings {
    restrictions: {
        restrict_checkout: boolean;
        restrict_checkout_message: string;
    };
    stripe_payments: boolean;
    cash_payments: boolean;
    delivery: {
        max: number;
    };
    promos: Array<Promo>;
    user: {
        profile: AuthUser;
    };
}

export interface PaymentMethod {
    method: string;
    amount: number;
}

export interface Metric {
    title: string;
    amount: number;
}

export interface ProductResponse {
    product: Product;
}

export interface CartItem {
    variant: ProductVariant;
    quantity: number;
}

export enum PromoType {
    BULK_DISCOUNT = 'bulk_discount',
    GET_ONE_FREE = 'get_one_free',
}

export interface PromoConfig {
    applicable_quantities: number[];
    percentage_discount: number;
    type: PromoType;
    title: string;
    max_per_user: number;
    max_per_day: number;
}

export interface Promo {
    message: string;
    title: string;
    slug: string;
    media: string;
    ends_in: string;
    config: PromoConfig;
    description: string;
}


export interface StripeResponse {
    session_url: string;
}
