// DO NOT EDIT THIS FILE DIRECTLY. MAKE CHANGES IN ./.env
export const environment = {
    VERSION: require('../../package.json').version,
    LAST_BUILD: require('../../package.json').lastBuild,
    GOOGLE_API_KEY: 'AIzaSyBrEX9bkAJfAhuLko8SzwXFFs_sTjFjNbM',
    APP_NAME: 'hilyfe.club',
    APP_DOMAIN: 'hilyfe.club',
    SUPPORT_EMAIL: 'support@hilyfe.club',
    GOOGLE_ANALYTICS_ID: 'UA-161739957-1',
    apiServer: 'https://api.hilyfe.club',
    production: true,
    environmentName: 'prod',
    whitelistedDomains: ['api.hilyfe.club'],
};
