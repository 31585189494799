import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from "../_services/authentication.service";

@Injectable()
export class DeliveryGuard implements CanActivate {

    constructor(private router: Router, private authenticationService: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        try {
            const authedUser = this.authenticationService.currentUser();
            if (authedUser && this.authenticationService.permissions.deliveryman) {
                return true;
            } else {
                this.router.navigate(['/tabs/home']);
                return false;
            }
        } catch (e) {
            return false;
        }

    }
}
