import * as localforage from 'localforage';
import * as i0 from "@angular/core";
export class LocalForageService {
    getStore(name) {
        return localforage.createInstance({ name });
    }
    setItem(store, key, data) {
        return localforage.createInstance({ name: store }).setItem(key, { data, created: new Date().toUTCString() });
    }
    removeItem(store, key, fn) {
        localforage.createInstance({ name: store }).removeItem(key, fn);
    }
    getItem(store, key) {
        return localforage.createInstance({ name: store }).getItem(key);
    }
    keys(store) {
        return localforage.createInstance({ name: store }).keys();
    }
}
LocalForageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalForageService_Factory() { return new LocalForageService(); }, token: LocalForageService, providedIn: "root" });
