import * as tslib_1 from "tslib";
import { DataService } from "../../../../_services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LocationService } from "../../../../_services/location.service";
import { AuthenticationService } from "../../../../_services/authentication.service";
import { ActionSheetController, AlertController } from "@ionic/angular";
import { LocalForageService } from '../../../../_services/local-forage.service';
export class OrderAcceptComponent {
    constructor(dataService, route, router, authenticationService, actionSheetController, alertController, locationService, localForageService) {
        this.dataService = dataService;
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.actionSheetController = actionSheetController;
        this.alertController = alertController;
        this.locationService = locationService;
        this.localForageService = localForageService;
        this.reference = '';
        this.order = null;
        this.pos = null;
        this.etas = ['In 20mins', 'In 40mins', 'In 1hr 20mins', 'In 2hrs', 'At 9AM tomorrow'];
        this.pendingOrdersCount = 0;
        this.maxAccept = 5;
    }
    get authUser() {
        return AuthenticationService.authedUser();
    }
    setupOrder() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.dataService.changeLoadingState(true);
            yield this.dataService.getOrder(this.reference).toPromise().then((data) => {
                this.order = data.order;
                this.pendingOrdersCount = data.pending_orders_count;
                this.dataService.changeLoadingState(false);
            }).catch(error => {
                this.dataService.handleNoCredentialsError(error);
            });
            let response;
            yield this.localForageService.getItem('hilyfe', 'setting').then(r => {
                response = r;
            });
            if (response && response.data) {
                this.maxAccept = response.data.delivery.max;
            }
        });
    }
    ionViewDidEnter() {
        this.reference = this.route.snapshot.queryParams.reference;
        this.setupOrder();
        this.locationService.getPosition().then(pos => this.pos = pos).catch(() => {
        });
    }
    provideETA() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const buttons = [];
            for (const eta of this.etas) {
                buttons.push({
                    text: eta,
                    handler: () => {
                        this.presentAlertConfirm(eta);
                    }
                });
            }
            buttons.push({
                text: 'Cancel',
                role: 'destructive',
                handler: () => {
                }
            });
            const actionSheet = yield this.actionSheetController.create({
                header: 'Provide an ETA',
                mode: "ios",
                buttons
            });
            yield actionSheet.present();
        });
    }
    presentAlertConfirm(eta) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                header: `Delivery for ${this.order.user.username} ${eta.toLowerCase()}. Proceed?`,
                mode: 'ios',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'destructive',
                        handler: () => {
                        }
                    }, {
                        text: 'Yes',
                        handler: () => {
                            this.dataService.changeLoadingState(true);
                            this.dataService.acceptOrder(this.reference, eta).subscribe((response) => {
                                if (response && response.order) {
                                    this.order = response.order;
                                }
                            }, (error) => {
                                this.dataService.changeLoadingState(false);
                                if (error.status === 403) {
                                    this.dataService.showSimpleToastMessage(error.error.message, 10000, 'danger');
                                }
                            }, () => {
                                this.dataService.changeLoadingState(false);
                                window.location.reload();
                            });
                        }
                    }
                ]
            });
            yield alert.present();
        });
    }
}
