<ion-card *ngIf="order" [class.w3-border-blue]="order.state !== 'cancelled'" class="w3-margin-bottom w3-border"
          mode="ios">
    <div *ngIf="showETADirections" class="w3-border-bottom">
        <div *ngIf="embedUrl">
            <iframe [src]="embedUrl" allowfullscreen
                    style="height: 30vh;border:0"
                    width="100%"></iframe>
            <div *ngIf="order?.deliveryman_assigned" class="w3-container w3-padding">
                <ion-button
                    [href]="'https://www.google.com/maps/dir/?api=1&destination='+order.delivery_location.lat+','+order.delivery_location.lng"
                    expand="block"
                    fill="outline"
                    target="_blank">Get directions
                </ion-button>
            </div>
        </div>
    </div>
    <ion-card-content>
        <div class="w3-margin-bottom">
            <ion-button (click)="showETADirections=true"
                        *ngIf="!showETADirections && view!=='orders' && order.state !== 'fulfilled'"
                        class="w3-margin-bottom"
                        expand="block" fill="outline">Show ETA & Directions
            </ion-button>
            <mat-chip-list>
                <ng-container *ngIf="order.state !== 'cancelled'">
                    <mat-chip *ngIf="order.state === 'pending'" color="accent" selected>Unfulfilled</mat-chip>
                    <mat-chip *ngIf="order.state === 'fulfilled'" selected>Fulfilled</mat-chip>
                    <mat-chip *ngIf="!order.paid" color="accent" selected>Unpaid</mat-chip>
                    <mat-chip *ngIf="order.paid" selected>Paid</mat-chip>
                    <mat-chip *ngIf="order.mode === 'offline'">
                        Offline&nbsp;<ion-icon name="cloud-offline-outline" size="small"></ion-icon>
                    </mat-chip>
                    <mat-chip>Payment: {{order?.payment_method}}</mat-chip>
                </ng-container>
                <mat-chip *ngIf="order.state === 'cancelled'">Cancelled</mat-chip>
            </mat-chip-list>
        </div>
        <div class="w3-margin-bottom">
            <h3 class="w3-large">Delivery for {{order.user.username}}
                <ng-container *ngIf="authedUser.profile_type == 'deliveryman'">
                    (#{{order.id}})
                </ng-container>
            </h3>
            <p class="menu-label is-marginless">
                {{order.created}}
            </p>
            <ng-container *ngIf="showSummary">
                <br>
                <label class="menu-label">
                    Order summary
                </label>
                <div class="columns is-multiline">
                    <div *ngFor="let s of order.summary;index as i" class="column">
                        <strong>Product: </strong> {{s.product_variant.product__title}}
                        <br>
                        <strong>Size:</strong> {{s.product_variant.product_size.title}}
                        <br>
                        <strong>Quantity:</strong> {{s.quantity}}
                        <span *ngIf="s.free_quantity">(+{{s.free_quantity}} free)</span>
                        <br>
                        <strong>Subtotal:</strong> ${{s.price}}
                        <div class="w3-hide-large w3-hide-medium">
                            <br>
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div *ngIf="order.delivery_location" class="w3-margin-bottom">
            <label class="menu-label">
                Location
            </label>
            <div>
                {{order.delivery_location.formatted_address}}
                <ng-container *ngIf="order.delivery_location.comment !== '-'">({{order.delivery_location.comment}})
                </ng-container>
                <br>
                <a
                    *ngIf="order.deliveryman_assigned?.username === order.user.username"
                    [href]="'https://www.google.com/maps/dir/?api=1&destination='+order.delivery_location.lat+','+order.delivery_location.lng"
                    target="_blank">Directions ◀</a>
            </div>
        </div>
        <div *ngIf="order.delivery_location && order.phone_number" class="w3-margin-bottom">
            <label class="menu-label">
                Phone
            </label>
            <p>
                <a [href]="'tel:'+order.phone_number">{{order.phone_number}}</a>
            </p>
        </div>
        <div class="w3-margin-bottom" *ngIf="showSummary">
            <strong class="w3-xxlarge w3-block">
                ${{order.amount_payable}}
            </strong>
            <ng-container *ngIf="order.discount_applied">
                (Discount: {{discount}})
            </ng-container>
        </div>
        <div *ngIf="order.state === 'pending'">
            <ng-container
                *ngIf="order.deliveryman_assigned?.username === authedUser.user.username && view === 'delivery'">
                <ion-button (click)="orderFulfilled(order)" class="w3-margin-bottom" color="primary"
                            expand="block">
                    Mark Order as fulfilled
                </ion-button>
            </ng-container>
        </div>
        <ng-content select="[accept]">

        </ng-content>
        <ion-button (click)="showMoreOptions(order)" *ngIf="view === 'delivery' && this.order.state !== 'fulfilled'"
                    class="w3-margin-bottom"
                    color="primary"
                    expand="block"
                    fill="outline">
            <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
        </ion-button>
    </ion-card-content>

</ion-card>
