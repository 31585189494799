import { environment } from "../environments/environment";
import { SentryHandlerFactory } from "./sentry";
const whitelistedDomains = environment.whitelistedDomains;
export function tokenGetter() {
    try {
        return localStorage.getItem('TOKEN');
    }
    catch (e) {
        return '';
    }
}
const ɵ0 = SentryHandlerFactory;
export class AppModule {
}
export { ɵ0 };
