import {Injectable} from '@angular/core';
import * as localforage from 'localforage';

@Injectable({
    providedIn: 'root'
})
export class LocalForageService {
    getStore(name: string) {
        return localforage.createInstance({name});
    }

    setItem(store: string, key: string, data: any): Promise<any> {
        return localforage.createInstance({name: store}).setItem(key, {data, created: new Date().toUTCString()});
    }

    removeItem(store: string, key: string, fn) {
        localforage.createInstance({name: store}).removeItem(key, fn);
    }

    getItem(store: string, key: string): Promise<any> {
        return localforage.createInstance({name: store}).getItem(key);
    }

    keys(store): Promise<any> {
        return localforage.createInstance({name: store}).keys();
    }
}
