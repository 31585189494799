import {CartItem, PromoType} from "./model";
import {LocalForageService} from "../_services/local-forage.service";

export function getCartAmount(cart: CartItem[], humanize): number;

export function getCartAmount(cart: CartItem[], humanize): string;

export function getCartAmount(cart: CartItem[], humanize = false): number | string {
    let amount = 0;
    for (const item of cart) {
        let amountToAdd = item.quantity * parseFloat(item.variant.price);
        if (item.variant.promo) {
            const promoConfig = item.variant.promo.config;
            if (promoConfig.type === PromoType.BULK_DISCOUNT) {
                if (promoConfig.applicable_quantities.includes(item.quantity) || promoConfig.applicable_quantities.length === 0) {
                    amountToAdd = (parseFloat(item.variant.price) * (1 - (promoConfig.percentage_discount / 100))) * item.quantity;
                }
            }
        }
        amount += amountToAdd;
    }
    return humanize ? amount.toFixed(2) : amount;
}

export async function getStoreData<T>(store: LocalForageService, key: string, fallback): Promise<T> {
    return (await store.getItem('hilyfe', key) || {data: fallback}).data;
}

export function setStoreData<T>(store: LocalForageService, key: string, data: T) {
    store.setItem('hilyfe', key, data);
}
