import {Component, Injectable, Input, OnInit} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators as FormValidators
} from '@angular/forms';
import {SplitPaymentItem} from "../order-fulfilled-dialog.component";
import {ModalController} from "@ionic/angular";

@Injectable()
export class Validators extends FormValidators {
    static bannedValue(bannedValue): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return control.value === bannedValue ? {isBanned: true} : null;
        };
    }
}

@Component({
    templateUrl: './order-split-payment-item.component.html',
    styleUrls: ['./order-split-payment-item.component.scss']
})
export class OrderSplitPaymentItemComponent implements OnInit {
    form: FormGroup;
    @Input() paymentMethods = [];
    @Input() remainingAmount = 0;
    @Input() defaultAmount = 0;
    @Input() total = 0;

    constructor(
        private fb: FormBuilder,
        public modalController: ModalController
    ) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            method: [this.paymentMethods[0], Validators.required],
            amount: [this.defaultAmount, Validators.compose([
                Validators.required,
                Validators.max(this.remainingAmount),
                Validators.bannedValue(this.total)
            ])]
        });
    }

    add() {
        if (!this.form.valid) {
            return;
        }
        const method = this.form.value.method;
        const amount = this.form.value.amount;
        const result: SplitPaymentItem = {method, amount};
        this.close(result);
    }

    close(result = null) {
        this.modalController.dismiss({
            dismissed: true,
            result
        });
    }
}
