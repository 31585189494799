import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable()
export class NotAuthGuard implements CanActivate {

    constructor(private router: Router, private helper: JwtHelperService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        try {
            const tokenExpired = this.helper.isTokenExpired(localStorage.getItem('TOKEN'));
            if (tokenExpired) {
                return true;
            } else {
                this.router.navigate(['/tabs/home']);
                return false;
            }
        } catch (e) {
            localStorage.clear();
            this.router.navigate(['welcome'], {queryParams: {next: window.location.pathname}});
            return false;
        }

    }
}
