import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {IonicModule} from '@ionic/angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtModule} from '@auth0/angular-jwt';
import {LocalForageService} from '../_services/local-forage.service';
import {DataService} from '../_services/data.service';
import {AuthGuard} from '../_guards/auth.guard';
import {NotAuthGuard} from '../_guards/not-auth.guard';
import {DeliveryGuard} from '../_guards/delivery.guard';
import {AuthenticationService} from '../_services/authentication.service';
import {Interceptor} from '../_services/interceptor.service';
import {environment} from "../environments/environment";
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import {LocationService} from "../_services/location.service";
import {UserApprovedGuard} from "../_guards/user-approved.guard";
import {SentryHandlerFactory} from "./sentry";
import {OrderAcceptComponent} from "./tabs/order/order-accept/order-accept.component";
import {OrderSummaryModule} from "./tabs/order/order-summary/order-summary.module";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatDividerModule} from "@angular/material/divider";
import {IndexComponent} from "./index/index.component";
import {ServiceWorkerModule} from '@angular/service-worker';
import {HerreGuard} from "../_guards/herre.guard";
import {OrderFulfilledDialogComponent} from "./tabs/order/order-fulfilled-dialog/order-fulfilled-dialog.component";
import {HoursOfOperationGuard} from "../_guards/hours-of-operation.guard";
import {HerreDeliveryGuard} from "../_guards/herre-delivery.guard";
import {EarningsGuard} from '../_guards/earnings.guard';
import {RestrictCheckoutGuard} from "../_guards/restrict-checkout.guard";
import {
    OrderSplitPaymentItemComponent
} from "./tabs/order/order-fulfilled-dialog/order-split-payment-item/order-split-payment-item.component";

const whitelistedDomains = environment.whitelistedDomains;

export function tokenGetter() {
    try {
        return localStorage.getItem('TOKEN');
    } catch (e) {
        return '';
    }
}

@NgModule({
    declarations: [
        AppComponent,
        IndexComponent,
        OrderAcceptComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        IonicModule.forRoot(
            {
                mode: 'md',
                animated: false
            }
        ),
        BrowserAnimationsModule,
        MatProgressBarModule,
        HttpClientModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
                authScheme: 'JWT ',
                whitelistedDomains: [...whitelistedDomains],
            }
        }),
        MatCardModule,
        MatButtonModule,
        OrderSummaryModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        MatDividerModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    ],
    providers: [
        LocalForageService,
        DataService,
        AuthGuard,
        UserApprovedGuard,
        HerreGuard,
        HerreDeliveryGuard,
        EarningsGuard,
        HoursOfOperationGuard,
        RestrictCheckoutGuard,
        NotAuthGuard,
        DeliveryGuard,
        AuthenticationService,
        LocationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true
        },
        {provide: ErrorHandler, useFactory: SentryHandlerFactory},
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        OrderFulfilledDialogComponent,
        OrderSplitPaymentItemComponent,
    ],

})
export class AppModule {
}




