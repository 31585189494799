import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DataService } from "../../../../_services/data.service";
import { ModalController } from '@ionic/angular';
import { OrderSplitPaymentItemComponent } from "./order-split-payment-item/order-split-payment-item.component";
export var PaymentStyle;
(function (PaymentStyle) {
    PaymentStyle["Single"] = "single";
    PaymentStyle["Multiple"] = "multiple";
})(PaymentStyle || (PaymentStyle = {}));
export class OrderFulfilledDialogComponent {
    constructor(dataService, modalController) {
        this.dataService = dataService;
        this.modalController = modalController;
        this.earnings = false;
        this.paid = false;
        this.orderCheckList = [];
        this.loading = false;
        this.selectedPaymentMethod = null;
        this.PaymentStyle = PaymentStyle;
        this.selectedStyle = PaymentStyle.Single;
        this.splitPayments = [];
    }
    get hasPaymentMethodBeenSelected() {
        if (this.selectedStyle === PaymentStyle.Single) {
            return this.selectedPaymentMethod !== null;
        }
        else if (this.selectedStyle === PaymentStyle.Multiple) {
            return this.splitPaymentTotalEqualsAmountPayable;
        }
        else {
            throw Error(`Not implemented for [${this.selectedStyle}]`);
        }
    }
    get disablePaymentSplitting() {
        return this.getSplittablePaymentMethods().length === 0 || this.splitPaymentTotalEqualsAmountPayable;
    }
    get splitPaymentTotalEqualsAmountPayable() {
        return parseFloat(this.order.amount_payable) === this.splitPaymentTotal;
    }
    get splitPaymentTotal() {
        return this.splitPayments.map(item => item.amount).reduce((a, b) => a + b, 0);
    }
    get orderSummaryChecked() {
        let checked = true;
        for (const item of this.orderCheckList) {
            if (item.checked === false) {
                checked = false;
                break;
            }
        }
        return checked;
    }
    ngOnInit() {
        if (this.order.payment_method !== 'offline') {
            this.selectedPaymentMethod = this.order.payment_method;
        }
        for (const item of this.order.summary) {
            this.orderCheckList.push({
                product_size__size: parseFloat(item.product_variant.product_size.size),
                product_variant: item.product_variant,
                quantity: item.quantity + item.free_quantity,
                desc: `${item.product_variant.product__title} • ${item.product_variant.product_size.title}`,
                checked: false
            });
        }
        this.paid = this.order.payment_method.toLowerCase().includes('stripe');
    }
    close() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss({
            completed: false
        });
    }
    confirmFulfilledOrder() {
        this.loading = true;
        this.dataService.changeLoadingState(true);
        let method = this.selectedPaymentMethod;
        if (this.selectedStyle === PaymentStyle.Multiple) {
            method = this.splitPayments;
        }
        this.dataService.orderFulfilled(this.order.reference, method).subscribe(() => {
            this.modalController.dismiss({
                completed: true,
                method
            });
            this.dataService.changeLoadingState(false);
            this.loading = false;
        }, (error) => {
            this.dataService.changeLoadingState(false);
            this.loading = false;
            this.dataService.showSimpleToastMessage(error.error[0], 5000, 'danger', 'bottom');
        });
    }
    styleChanged($event) {
        this.selectedStyle = $event.detail.value;
    }
    addSplitPayment() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const remainingAmount = parseFloat(this.order.amount_payable) - this.splitPaymentTotal;
            const modal = yield this.modalController.create({
                component: OrderSplitPaymentItemComponent,
                componentProps: {
                    paymentMethods: this.getSplittablePaymentMethods(),
                    remainingAmount,
                    defaultAmount: this.splitPayments.length ? remainingAmount : 0,
                    total: parseFloat(this.order.amount_payable)
                }
            });
            yield modal.present();
            const { data, role } = yield modal.onWillDismiss();
            if (data.result) {
                this.splitPayments.push(data.result);
            }
        });
    }
    removeSplitPayment(payment) {
        this.splitPayments = this.splitPayments.filter(item => item !== payment);
    }
    getSplittablePaymentMethods() {
        return this.order.available_payment_methods.filter(method => {
            return !['stripe', ...this.splitPayments.map(item => item.method)].includes(method);
        });
    }
}
